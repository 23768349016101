import { graphql } from 'gatsby';
import * as React from 'react';
import { Container } from 'react-bootstrap';
import Navbar from '../components/Navbar/Navbar';
import '../style/main.scss';
import 'katex/dist/katex.min.css';

// eslint-disable-next-line react/prop-types
export default function BlogPostTemplate({ data: { markdownRemark } }) {
  // eslint-disable-next-line react/prop-types
  const { frontmatter, html } = markdownRemark;
  return (
    <div>
      <Navbar />
      <section id="blogpost">
        <Container>
          {/* eslint-disable-next-line react/prop-types */}
          <h1>{frontmatter.title}</h1>
          <p>By Peter Blain</p>
          {/* eslint-disable-next-line react/prop-types */}
          <h2>{frontmatter.date}</h2>
          <div className="post-body" dangerouslySetInnerHTML={{ __html: html }} />
        </Container>
      </section>
    </div>
  );
}

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
      }
    }
  }
`;
